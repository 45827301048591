import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TaskDepot } from 'src/app/classes/task_depot.model';
import { ApiService } from 'src/app/services/api.service';
import { TaskService } from 'src/app/services/task.service';

@Component({
  selector: 'select-depot.modal',
  templateUrl: './select-depot.modal.html',
  styleUrls: ['./select-depot.modal.scss'],
})
export class SelectDepotModal {
  query: string;
  attribute: string;
  depots: TaskDepot[];
  loading = true;

  constructor(private api: ApiService, private modalController: ModalController, private taskService: TaskService) { }

  async ngOnInit() {
    this.query = "";
    this.attribute = "name";
    this.depots = await this.api.get(`depots`);
    this.loading = false;
  }

  dismiss(depot: TaskDepot = null) {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({ depot });
  }

  selectDepot(depot: TaskDepot) {
    this.dismiss(depot);
  }
}
