import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { SelectDepotModal } from './select-depot.modal';
import { ProductFilterModule } from 'src/app/components/product-filter-module/product-filter.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, ProductFilterModule],
  declarations: [SelectDepotModal],
})
export class SelectDepotModalModule { }
