import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'productFilter',
})
export class ProductFilterPipe implements PipeTransform {
  transform(items: any[], query: string): any {
    let search = query.replace(/[- ]/g, '').toLowerCase();
    return items.filter(item => Object.values(item).some(value => typeof value == 'string' && value.replace(/[- ]/g, '').toLowerCase().indexOf(search) > -1))
  }
}
