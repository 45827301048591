import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private toastCtrl: ToastController, private authService: AuthService, private http: HttpClient) {}

  async get<T>(url: string): Promise<any> {
    const token = this.authService.token;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'ngsw-bypass': 'true', //bypass serviceworker for get-requests
    });

    return this.http
      .get<any>(environment.webservice.baseurl + url, { headers })
      .toPromise()
      .catch((error) => {
        this.handleError(error);
      });
  }

  async put<T>(url, data = {}): Promise<any> {
    const token = this.authService.token;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    return this.http
      .put<any>(environment.webservice.baseurl + url, data, {
        headers,
      })
      .toPromise()
      .catch((error) => this.handleError(error));
  }

  async delete<T>(url): Promise<any> {
    const token = this.authService.token;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    return this.http
      .delete<any>(environment.webservice.baseurl + url, {
        headers,
      })
      .toPromise()
      .catch((error) => this.handleError(error));
  }

  async post<T>(url, data = {}): Promise<any> {
    const token = this.authService.token;
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });

    return this.http
      .post<any>(environment.webservice.baseurl + url, data, {
        headers,
      })
      .toPromise()
      .catch((error) => this.handleError(error));
  }

  handleError(error) {
    if (error.status === 401) {
      this.authService.logout();
    }

    this.presentToast(error?.error?.message ?? error.message);
  }

  async presentToast(message: string, header: string = 'Der opstod en fejl!', color = 'danger', duration = 3000) {
    const toast = await this.toastCtrl.create({
      color,
      header,
      message,
      duration,
    });
    toast.present();
  }
}
