import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { SelectProductModal } from './select-product.modal';
import { ProductFilterModule } from 'src/app/components/product-filter-module/product-filter.module';

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, ProductFilterModule],
  declarations: [SelectProductModal],
})
export class SelectProductModalModule { }
