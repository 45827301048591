import { Product } from './product.model';
import { TaskDepot } from './task_depot.model';

export class TaskProduct {
  id: number;
  amount: number;
  product_id?: number;
  depot_id?: number;
  internal_note?: string;
  external_note?: string;
  weightment_slip?: string;
  depot?: TaskDepot;
  product?: Product;

  constructor(obj?: any) {
    Object.assign(this, obj);
  }
}
