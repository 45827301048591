import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Product } from 'src/app/classes/product.model';
import { ApiService } from 'src/app/services/api.service';
import { TaskService } from 'src/app/services/task.service';

@Component({
  selector: 'select-product.modal',
  templateUrl: './select-product.modal.html',
  styleUrls: ['./select-product.modal.scss'],
})
export class SelectProductModal {
  @Input() types: string;
  query: string;
  attribute: string;
  products: Product[];
  loading = true;

  constructor(private api: ApiService, private modalController: ModalController, private taskService: TaskService) { }

  async ngOnInit() {
    this.query = "";
    this.attribute = "c5_no";
    this.products = await this.api.get(`products?types=${this.types}`);
    this.loading = false;
  }

  dismiss(product: Product = null) {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({ product });
  }

  selectProduct(product: Product) {
    this.dismiss(product);
  }
}
